"use client";
import "./globals.css";
import Head from "./head";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <Head />
      <body className="bg-black font-campton-light">
          {children}
          <ToastContainer />
      </body>
    </html>
  );
}
